import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { UIKitProvider, Header, Dropdown, Toggle as DropdownToggle, Item as DropdownItem, Body as DropdownBody } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "header"
    }}>{`Header`}</h1>
    <Props of={Header} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
        <Header customSx={{
        backgroundColor: 'red'
      }} left="Left" right="Right" mdxType="Header">
            This is a header
        </Header>
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      